import React from 'react';
import { useNavigate } from 'react-router-dom';

import pages from '../../const/pages';
import avatarPlaceholder from '../../assets/V2/avatar-placeholder.svg';

const UserCard = ({ customer }) => {
    const navigate = useNavigate();

    return (
        <div className="user-card-wrapper">
            <div className="text-wrapper">
                <div className="dashboard-title">Dashboard</div>
                <div className="dashboard-name">{customer.firstName}</div>
            </div>
            <div className="user-avatar-wrapper" onClick={() => navigate(pages.secureProfile)}>
                <img className="avatar" src={avatarPlaceholder} />
            </div>
        </div>
    );
};

export default UserCard;
