import React, { useMemo } from 'react';

import Header from '../../components/V2/Header';
import trans from '../../trans';
import CashbackCard from '../../components/V2/CashbackCard';

import coinRound from '../../assets/V2/coinRound.png';
import cashback from '../../assets/V2/cashback.svg';
import '../../styles/V2/index.scss';
import DashboardButton from '../../components/V2/DashboardButton';
import { useNavigate } from 'react-router-dom';
import pages from '../../const/pages';
import { observer } from 'mobx-react';
import PageWrapper from '../../components/V2/PageWrapper';
import PopupModal from '../../components/cashback/PopupModal';

const CashbackPage = observer(({ UserStore, ContractsStore, DataStore }) => {
    const { customer } = UserStore;
    const navigate = useNavigate();
    const [expectedModal, setExpectedModal] = React.useState(false);
    const [availableModal, setAvailableModal] = React.useState(false);

    const productTokensData = useMemo(() => {
        const tokens = Array.from(ContractsStore.contracts.values())
            .map((contract) => DataStore.getProduct(contract.productType))
            .reduce((acc, prod) => acc + (prod.forecastTokenValues ?? 0), 0);

        return {
            tokens,
            totalSum: tokens * 0.01,
        };
    }, [ContractsStore.contracts]);

    const renderModals = () => (
        <>
            <PopupModal
                title={trans.t('cashbackPage', 'expectedCashBack')}
                text={trans.t('cashbackPage', 'expectedModalText')}
                open={expectedModal}
                closeModal={() => setExpectedModal(false)}
            />
            <PopupModal
                title={trans.t('cashbackPage', 'availableCashback')}
                text={trans.t('cashbackPage', 'modalText')}
                open={availableModal}
                closeModal={() => setAvailableModal(false)}
            />
        </>
    );

    return (
        <PageWrapper
            className="cashback-background"
            contentClassName="cashback-new"
            modals={renderModals()}
            header={<Header backButton title={trans.t('cashbackPage', 'cashbackAccount')} />}
        >
            <div className="cashback-cards-wrapper">
                <CashbackCard
                    title={trans.t('cashbackPage', 'expectedCashBack')}
                    subtitle={trans.t('cashbackPage', 'expectedSubtitle')}
                    tokens={productTokensData.tokens}
                    totalSum={productTokensData.totalSum}
                    onInfoClick={() => setExpectedModal(true)}
                />
                <CashbackCard
                    title={trans.t('cashbackPage', 'availableCashback')}
                    subtitle={trans.t('cashbackPage', 'availableSubtitle')}
                    tokens={customer.sumPayedTokenValues}
                    totalSum={customer.totalCashbackSum}
                    green
                    onInfoClick={() => setAvailableModal(true)}
                />
            </div>
            <div className="cashback-buttons-row">
                <DashboardButton
                    iconSrc={cashback}
                    mainText={<>{trans.t('cashbackPage', 'payCashback')}</>}
                    cashback
                    onClick={() => navigate(pages.cashbackPayment)}
                />
                <DashboardButton
                    iconSrc={coinRound}
                    mainText={<>{trans.t('cashbackPage', 'exchangeCashback')}</>}
                    cashback
                    disabled
                />
            </div>
        </PageWrapper>
    );
});

export default CashbackPage;
