import React from 'react';
import PropTypes from 'prop-types';

const DashboardButton = ({ iconSrc, mainText, subText, cashback, onClick, disabled }) => (
    <div className={`dashboard-big-button ${cashback ? 'cashback' : ''} ${disabled ? 'disabled' : ''}`} onClick={onClick}>
        <div className="icon-wrapper">
            <img src={iconSrc} alt="Icon" className="icon-inner" />
        </div>
        <div className="text">
            <div className="main-text">
                {mainText}
            </div>
            <div className="sub-text">
                {subText}
            </div>
        </div>
    </div>
);

DashboardButton.propTypes = {
    iconSrc: PropTypes.string.isRequired,
    mainText: PropTypes.node.isRequired,
    subText: PropTypes.node.isRequired,
    cashback: PropTypes.bool,
};

export default DashboardButton;
