import React from 'react';

import Header from '../../components/theme/HeaderV2';
import Button from '../../components/dashboard/Button';

import trans from '../../trans';
import '../../styles/V1/app.scss';

const BusinessPartnerWerden = () => {
    return (
        <div className="app secure-dashboard-page">
            <Header backButton title={trans.t('partnerPage', 'title')} noMenu />
            <div className="main-content business-partner-werden">
                <div className="gray-box">
                    <div className="description" data-i18n="[html]content.body">
                        {trans.t('partnerPage', 'description')}
                    </div>
                </div>
                <div className="title">{trans.t('partnerPage', 'videoTitle')}</div>
                <iframe
                    width="280"
                    height="140"
                    src="https://www.youtube.com/embed/IB5qg81T8hg"
                    title="Business partner werden"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    // referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                />
                <ul>
                    <li><span>{trans.tHTML('partnerPage', 'maximizeIncome')}</span></li>
                    <li><span>{trans.tHTML('partnerPage', 'simpleManagment')}</span></li>
                    <li><span>{trans.tHTML('partnerPage', 'simpleManagment')}</span></li>
                </ul>
                <Button
                    green
                    big
                    title={trans.t('termsPage', 'btnNext')}
                />
                <div className="button-subtitle">{trans.t('partnerPage', 'price')}</div>
            </div>
        </div>
    );
};

export default BusinessPartnerWerden;
