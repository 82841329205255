import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';

import Loading from '../../components/elements/Loading';
import Alert from '../../components/popups/Alert';

import logoFull from '../../assets/logoFull.svg';
import InputText from '../../components/V2/InputText';
import Select from '../../components/V2/Select';
import Utils from '../../lib/Utils';
import Button from '../../components/V2/Button';
import trans from '../../trans';
import countries from '../../const/countries';
import pageUrl from '../../const/pages';
import Validation from '../../lib/Validation';

const LoginPage = observer(({ UserStore, ContractsStore }) => {
    const { state } = useLocation();
    const navigate = useNavigate();

    const stateEmail = state && state.hasOwnProperty('email') ? state.email : '';
    let statePhone = state && state.hasOwnProperty('phone') ? state.phone : '';
    const isCompany = state && state.hasOwnProperty('isCompany') ? state.isCompany : !!stateEmail;

    if (statePhone.indexOf('00') === 0 && statePhone.length > 4) {
        statePhone = statePhone.substring(4);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [phoneCode, setPhoneCode] = useState(countries.deutschland.code);
    const [phoneNumber, setPhoneNumber] = useState(statePhone);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(stateEmail);

    const refPhoneCode = useRef();
    const refPhoneNumber = useRef();
    const refAlert = useRef();
    const refPassword = useRef();
    const refEmail = useRef();

    const openForgotPasswordPage = () => {
        //go to forgot password
        navigate(pageUrl.authForgotEmailSend);
    };

    //button handler
    const next = async () => {
        if (isCompany) {
            const validObj = refEmail.current.validate();
            if (!validObj.isValid) {
                refAlert.current.error(validObj.validateMsg);
                return false;
            }
        } else {
            const validObj = refPhoneNumber.current.validate();
            if (!validObj.isValid) {
                refAlert.current.error(validObj.validateMsg);
                return false;
            }
        }
        setIsLoading(true);
        let res = {};
        if (!isCompany) {
            res = await UserStore.doLoginWithPhone({ phoneCode, phoneNumber, password });
        } else {
            res = await UserStore.doLoginWithEmail({ email, password });
        }
        setIsLoading(false);
        if (!res) {
            return false;
        }
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }

        await ContractsStore.loadStore();
        navigate(pageUrl.secureDashboard);
    };

    return (
        <div className="main-wrapper login-page login-background">
            <div className="main-content-new login-dimensions">
                <img src={logoFull} alt="logo" className="logo-full" />
                <div className="subtitle">
                    Versicherungs- und Energieverträge können mehr
                </div>
                <div className="form-wrapper">
                    {!isCompany && (
                        <Select
                            ref={refPhoneCode}
                            options={Utils.getPhoneCodeList()}
                            onChange={(val) => setPhoneCode(val)}
                            defaultValue={phoneCode}
                        />
                    )}
                    {!isCompany && (
                        <InputText
                            ref={refPhoneNumber}
                            role="phoneNumberTestId"
                            placeholder={trans.t('signinPage', 'phoneNumber')}
                            onChange={(val) => setPhoneNumber(val)}
                            validateFunc={(val) => Validation.phone(val, phoneCode)}
                        />
                    )}
                    {isCompany && (
                        <InputText
                            placeholder="E-Mail-Adresse"
                            ref={refEmail}
                            role="emailTestId"
                            onChange={(val) => setEmail(val)}
                            validateFunc={(val) => Validation.email(val)}
                        />
                    )}
                    <InputText
                        placeholder={trans.t('signinPage', 'password')}
                        role="passwordTestId"
                        ref={refPassword}
                        type="password"
                        onChange={(val) => setPassword(val)}
                    />
                    <div className="forget-password" onClick={openForgotPasswordPage}>{trans.t('signinPage', 'buttomLink')}?</div>
                    <Button
                        green
                        title={trans.t('signinPage', 'btnNext')}
                        onClick={next}
                    />
                </div>
                <div className="signup-wrapper">
                    <div>Du hast noch keinen Account?</div>
                    <Button
                        title={trans.t('signinPage', 'register')}
                        brown
                        onClick={() => navigate(pageUrl.onboardingTerms)}
                    />
                </div>
            </div>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default LoginPage;
