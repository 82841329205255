/**
 * Created by Max Gornostayev on 04/30/22
 *
 * path: /auth/sms-code
 * id: authSignupStepTwo
 *
 * this is a page that shows second-step registration form,
 * after this page we need go to the third-step page and read some information in our the app
 */

import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { timer } from 'rxjs';
import { isMobile } from 'react-device-detect';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import Loading from '../../components/elements/Loading';
import SignupSteps from '../../components/elements/SignupSteps';
import DateUtil from '../../lib/DateUtil';
import '../../styles/V1/auth.scss';

import '../../styles/V1/app.scss';

const SignupStepTwoPage = observer(({ UserStore }) => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [countdownTitle, setCountdownTitle] = useState('5:00');
    const [startTime, setStartTime] = useState(DateUtil.timestamp());
    const [isExpired, setIsExpired] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [value, setValue] = useState('');
    const [value1, setValue1] = useState('');
    const [value2, setValue2] = useState('');
    const [value3, setValue3] = useState('');
    const [value4, setValue4] = useState('');
    const [value5, setValue5] = useState('');

    //ref variables
    const refAlert = useRef();
    const refInput = useRef();
    const refInput1 = useRef();
    const refInput2 = useRef();
    const refInput3 = useRef();
    const refInput4 = useRef();
    const refInput5 = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //this is subscription variable
    let subscription = null;

    //this is countdown handler
    const countDown = () => {
        if (!isExpired) {
            const currentTime = DateUtil.timestamp();
            const diff = currentTime - startTime;
            const countdown = 300 - diff;
            setCountdownTitle(DateUtil.getCountdownTitle(countdown));
            if (countdown <= 0) {
                stopCountDown();
                setIsExpired(true);
            }
        }
    };

    //Stop countdown handler
    const stopCountDown = () => {
        if (subscription && subscription.unsubscribe) subscription.unsubscribe();
    };

    //start countdown when page is mounted
    useEffect(() => {
        subscription = timer(0, 1000).subscribe(() => countDown());

        return stopCountDown;
    }, []);

    //handling next button click
    const next = async () => {
        //handling situation when time is left
        if (isExpired) {
            setCountdownTitle('5:00');
            setStartTime(DateUtil.timestamp());
            setIsExpired(false);
            setIsValid(true);
            setValue1('');
            setValue2('');
            setValue3('');
            setValue4('');
            setValue5('');
            setValue('');

            subscription = timer(0, 1000).subscribe(() => countDown());
            const { customer } = UserStore;
            const res = await customer.sendOTP();
            if (!res.status) {
                refAlert.current.error(res.msg);
            }
            return false;
        }
        //handling situation when some of the input is empty
        if ((isMobile && value === '') || (!isMobile && (value1 === '' || value2 === '' || value3 === '' || value4 === '' || value5 === ''))) {
            setIsValid(false);
            return false;
        }

        //sending confirm request and if there is a mistake show it
        setIsLoading(true);
        const { customer } = UserStore;
        let code = '';
        code += isMobile ? value : value1 + value2 + value3 + value4 + value5;
        const res = await customer.sendOTPConfirm(code);
        setIsLoading(false);
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        } else {
            if (res.data.auth) {
                UserStore.setAuthTokens(res.data.auth.authToken, res.data.auth.refreshToken);
            }
        }
        //go to next page
        navigate(pageUrl.authSignupStepThree, { state: { emailToken: res.data.emailToken, passwordToken: res.data.passwordToken } });

        return true;
    };

    return (
        <div className="app auth signup-step-2-page">
            <Header isBack expertMsg={trans.t('signupStepTwoPage', 'expertMsg')} />
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-top-inner">
                        <h1>{trans.t('signupStepTwoPage', 'h1')}</h1>
                        <SignupSteps currentStep={2} />
                    </div>
                    <div className="sms-verification">
                        <div className="sms-verification-text-wrapper">
                            <div className="prefix-text-sms-verif">{trans.t('signupStepTwoPage', 'prefixBeforeTimerSmsVerif')}</div>
                            <div className="timer-text-sms-verif">
                                {countdownTitle} {trans.t('signupStepTwoPage', 'textAfterTimerSmsVerif')}
                            </div>
                        </div>
                        {!isExpired && (
                            <div className="wrapper-inputs">
                                {isMobile && (
                                    <input
                                        ref={refInput}
                                        role="allnumberTestId"
                                        className={!isValid && !value1 ? 'one-code redBorder' : 'one-code'}
                                        type="text"
                                        value={value}
                                        maxLength="5"
                                        onChange={(e) => setValue(e.target.value)}
                                    />
                                )}
                                {!isMobile && (
                                    <input
                                        ref={refInput1}
                                        role="number1TestId"
                                        className={!isValid && !value1 ? 'redBorder' : ''}
                                        type="text"
                                        value={value1}
                                        maxLength="1"
                                        onChange={(e) => {
                                            setValue1(e.target.value);
                                            refInput2.current.focus();
                                        }}
                                        onFocus={() => setValue1('')}
                                    />
                                )}
                                {!isMobile && (
                                    <input
                                        ref={refInput2}
                                        role="number2TestId"
                                        className={!isValid && !value2 ? 'redBorder' : ''}
                                        type="text"
                                        value={value2}
                                        maxLength="1"
                                        onChange={(e) => {
                                            setValue2(e.target.value);
                                            refInput3.current.focus();
                                        }}
                                        onKeyDown={(e) => {
                                            const key = e.which || e.keyCode || e.charCode;
                                            if (key === 8) refInput1.current.focus();
                                        }}
                                        onFocus={() => setValue2('')}
                                    />
                                )}
                                {!isMobile && (
                                    <input
                                        ref={refInput3}
                                        role="number3TestId"
                                        className={!isValid && !value3 ? 'redBorder' : ''}
                                        type="text"
                                        value={value3}
                                        maxLength="1"
                                        onChange={(e) => {
                                            setValue3(e.target.value);
                                            refInput4.current.focus();
                                        }}
                                        onKeyDown={(e) => {
                                            const key = e.which || e.keyCode || e.charCode;
                                            if (key === 8) refInput2.current.focus();
                                        }}
                                        onFocus={() => setValue3('')}
                                    />
                                )}
                                {!isMobile && (
                                    <input
                                        ref={refInput4}
                                        role="number4TestId"
                                        className={!isValid && !value4 ? 'redBorder' : ''}
                                        type="text"
                                        value={value4}
                                        maxLength="1"
                                        onChange={(e) => {
                                            setValue4(e.target.value);
                                            refInput5.current.focus();
                                        }}
                                        onKeyDown={(e) => {
                                            const key = e.which || e.keyCode || e.charCode;
                                            if (key === 8) refInput3.current.focus();
                                        }}
                                        onFocus={() => setValue4('')}
                                    />
                                )}
                                {!isMobile && (
                                    <input
                                        ref={refInput5}
                                        role="number5TestId"
                                        className={!isValid && !value5 ? 'redBorder' : ''}
                                        type="text"
                                        value={value5}
                                        maxLength="1"
                                        onChange={(e) => {
                                            setValue5(e.target.value);
                                        }}
                                        onKeyDown={(e) => {
                                            const key = e.which || e.keyCode || e.charCode;
                                            if (key === 8 && !value5) refInput4.current.focus();
                                        }}
                                        onFocus={() => setValue5('')}
                                    />
                                )}
                            </div>
                        )}
                        {isExpired && <div className="wrapper-inputs">{trans.t('signupStepTwoPage', 'isExpiredText')}</div>}
                    </div>
                </div>
            </div>
            <Bottom>
                <Button role="btnNext" text={trans.t('signupStepTwoPage', !isExpired ? 'btnNext' : 'btnSendAgain')} onClick={next} isRightIcon={!isExpired} />
            </Bottom>
            <BottomLinks />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignupStepTwoPage;
