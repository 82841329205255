import React from 'react';

export const Switcher = ({ firstTitle, secondTitle, firstFClick, secondClick, active }) => {
    return (
        <div className="switcher-wrapper">
            <div className={`switcher-item ${active === 'first' ? 'active' : ''}`} onClick={firstFClick}>{firstTitle}</div>
            <div className={`switcher-item ${active === 'second' ? 'active' : ''}`} onClick={secondClick}>{secondTitle}</div>
        </div>
    );
};
