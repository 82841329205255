/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingContractAdd
 * path: /onboarding/vertrag/import
 *
 * this is a page for selecting how to add contracts - through finApi or manual - in onboarding proccess
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
// import FinAPICore from '../../core/FinAPI';
import Loading from '../../components/elements/Loading';
import Header from '../../components/theme/Header';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import logo from '../../assets/V2/conunityLogo.svg';
import '../../styles/V1/app.scss';
import checkMark from '../../assets/V2/checkMarkBlue.svg';
import checkMarkGreen from '../../assets/V2/checkMarkGreen.svg';
const ContractAddPage = observer(({ ContractsStore, UserStore }) => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    // const [isError, setIsError] = useState(false);
    const { isAuthorized } = UserStore;

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();
    //get prop 'isContractAdded' from another screen
    // const { state } = useLocation();

    //ref variable for popup
    const refAlert = useRef();

    //generate webform and go to this page
    // const finAPIStart = async () => {
    //     setIsLoading(true);

    //     const user = FinAPICore.generateFinApiCustomer();
    //     const res = await FinAPICore.getWebURL(user);
    //     setIsLoading(false);
    //     if (!res.status) {
    //         refAlert.current.error(trans.t('onboardingContractAddPage', 'finapiError'));
    //         return false;
    //     }
    //     navigate(pageUrl.onboardingFinApiImport, { state: { formId: res.data.id } });

    //     return true;

    //     // ContractsStore.setFinAPIContracts(data);
    //     // navigate(pageUrl.onboardingContractList, { state: { isFinapi: true } });
    // };

    const handleInsuranceClick = () => {
        navigate(pageUrl.onboardingContractList);
        ContractsStore.setTempContractType('insurance');
    };

    const handleUtilitiesClick = () => {
        // TODO: Implement utilities flow
        navigate(pageUrl.onboardingContractList);
        ContractsStore.setTempContractType('strom');
    };

    const handleSkip = async () => {
        // navigate(`${pageUrl.onboardingContractList}?type=strom`);
        // TODO saving contracts
        const { customer } = UserStore;
        const contractIds = [ContractsStore.getInsuranceContracts(true), ContractsStore.getStromContracts(true)].flatMap(contracts => contracts.map(contract => contract.idValue));

        if (isAuthorized && customer.isHaveBroker) {
            setIsLoading(true);
            ContractsStore.addTempContractForBroker(contractIds);
            let res = await ContractsStore.saveTempContracts();
            if (!res.status) {
                setIsLoading(false);
                refAlert.current.error(res.msg);
                return null;
            }
            res = await ContractsStore.activateBrokerForCheckedContracts();
            setIsLoading(false);

            if (!res.status) {
                refAlert.current.error(res.msg);
                return null;
            }

            navigate(pageUrl.secureDashboard);
            return true;
        }

        if (!contractIds.length) {
            refAlert.current.show(trans.t('onboardingContractListPage', 'errorNotSelected'), 'error');
            // setIsError(true);
            return null;
        }

        ContractsStore.addTempContractForBroker(contractIds);
        navigate(pageUrl.onboardingContractListFinish);

        return true;
    };

    const tempInsuranceContracts = ContractsStore.getInsuranceContracts(true);
    const insuranceContracts = ContractsStore.getInsuranceContracts(false);

    const tempStromContracts = ContractsStore.getStromContracts(true);
    const stromContracts = ContractsStore.getStromContracts(false);

    const isInsuranceContractsExist = isAuthorized ? !!insuranceContracts.length : !!tempInsuranceContracts.length;
    const isStromContractsExist = isAuthorized ? !!stromContracts.length : !!tempStromContracts.length;
    const isNextButton = !!tempInsuranceContracts.length || !!tempStromContracts.length;

    return (
        <div className="app">
            <Header expertMsg={trans.t('onboardingContractAddPage', 'expertMsg')} />
            <div className="content contract-add-page">
                <div className="header-section">
                    <img src={logo} alt="logo" className="title-logo" />
                    <div className="page-title">Legen wir los!</div>
                </div>
                <div className="description">
                    Mit welchen Verträgen möchtest du starten?
                </div>
                <div className="buttons-container">
                    <button
                        type="button"
                        className="contract-button insurance"
                        onClick={handleInsuranceClick}
                    >
                        <span className="button-text">Versicherung suchen</span>
                        <span className="button-icon">
                            {isInsuranceContractsExist ? <img src={checkMark} alt="check" className="check-mark" /> : '+'}
                        </span>
                    </button>
                    <button
                        type="button"
                        className="contract-button utilities"
                        onClick={handleUtilitiesClick}
                    >
                        <span className="button-text">Strom und Gas</span>
                        <span className="button-icon">
                            {isStromContractsExist ? <img src={checkMarkGreen} alt="check" className="check-mark" /> : '+'}
                        </span>
                    </button>
                </div>
                <div style={{ flex: 1 }} />
                {isNextButton && (
                    <button
                        type="button"
                        className="skip-button"
                        onClick={handleSkip}
                    >
                        Weiter
                    </button>
                )}
            </div>
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default ContractAddPage;
