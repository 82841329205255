import { observer } from 'mobx-react';
import React from 'react';

import CashbackCard from '../../components/cashback/CasshbackCard';
import Header from '../../components/theme/HeaderV2';
import InputText from '../../components/forms/InputText';
import Button from '../../components/dashboard/Button';
import trans from '../../trans';

const PaymentPage = observer(({ UserStore }) => {
    const { customer } = UserStore;

    return (
        <div className="app secure-dashboard-page">
            <Header backButton title={trans.t('cashbackPage', 'cashbackAccount')} />
            <div className="main-content dashboard">
                <CashbackCard
                    title={trans.t('cashbackPage', 'availableCashback')}
                    subtitle={trans.t('cashbackPage', 'availableSubtitle')}
                    tokens={customer.sumPayedTokenValues}
                    totalSum={customer.totalCashbackSum}
                    green
                />
                <InputText
                    title="Betrag"
                    className="margin-top-16"
                    validateFunc={(val) => {
                        if (val > customer.sumPayedTokenValues) {
                            return { validated: false, msg: `Maximalwert ist ${customer.sumPayedTokenValues}` };
                        }

                        return { validated: true, msg: '' };
                    }}
                />
                <InputText
                    title="IBAN"
                    className="margin-top-16"
                />
                <InputText
                    title="Bankname"
                    className="margin-top-16"
                />
                <Button title="Auszahlen" big className="margin-top-32" />
            </div>
        </div>
    );
});

export default PaymentPage;
