/**
 * Created by Max Gornostayev on 12/24/22
 *
 * pageId: startPrivate
 * path: /start/company
 *
 * this is a start onboarding for company - that goes to terms automatically
 */

import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/elements/Loading';
import pageUrl from '../../const/pages';

import '../../styles/V1/app.scss';

const StartCompanyPage = observer(({ UserStore, ContractsStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //start countdown when page is mounted
    useEffect(() => {
        ContractsStore.clearStore();
        UserStore.clearStore();
        UserStore.setIsCompany(true);
        navigate(pageUrl.onboardingTerms);
    }, []);

    //main rendering
    return (
        <div className="app" role="app">
            <Loading isShow />
        </div>
    );
});

export default StartCompanyPage;
