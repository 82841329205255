/**
 * Created by Max Gornostayev on 02/15/22
 *
 * path: /
 *
 * this is home page
 */

// eslint-disable-next-line
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import pageUrl from '../const/pages';
import { useNavigate, useSearchParams } from 'react-router-dom';

const HomePage = observer(({ UserStore, ContractsStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //get search query object
    const [searchParams] = useSearchParams();
    //is authorized
    const { isAuthorized } = UserStore;
    if (!isAuthorized) {
        //clear stores
        UserStore.clearStore();
        ContractsStore.clearStore();
        //get and save customer data from url params
        UserStore.setUserDataFromQuery(searchParams);
    }

    //ismount function
    useEffect(() => {
        navigate(isAuthorized ? pageUrl.secureDashboard : pageUrl.authSigninPage);
    }, []);

    return null;
});

export default HomePage;
