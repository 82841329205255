import React from 'react';

import chevronRightBlue from '../../assets/V2/chevronRightBlue.svg';
import chevronRightGreen from '../../assets/V2/chevronRightGreen.svg';
import chevronRightYellow from '../../assets/V2/chevronRightYellow.svg';
import chevronRightBrown from '../../assets/V2/chevronRightBrown.svg';
import chevronRightWhite from '../../assets/V2/chevronRightWhite.svg';

const ContractsCard = ({ title, contractCount, tokensCount, green, yellow, brown, description, isDisabled, onClick, onButtonClick }) => {
    const chevronMap = {
        [green]: chevronRightGreen,
        [yellow]: chevronRightYellow,
        [brown]: chevronRightBrown,
        [!green && !yellow && !brown]: chevronRightBlue,
    };

    const colorMap = {
        [green]: 'green',
        [yellow]: 'yellow',
        [brown]: 'brown',
        [!green && !yellow && !brown]: 'blue',
    };

    const chevron = chevronMap.true;

    return (
        <div className={`contracts-card ${isDisabled ? 'disabled' : ''} ${colorMap.true}`} onClick={onClick}>
            <div className={`details-wrapper ${colorMap.true}`}>
                <div className={`details-title ${isDisabled ? 'disabled' : ''}`}>{title}</div>
                <div className="details-second-row">
                    {contractCount !== undefined && (
                        <>
                            <div className={`contract-count ${isDisabled ? 'disabled' : ''} ${colorMap.true}`}>{contractCount}</div>
                            {!isDisabled && <div className="count-description">Vertragsübersicht</div>}
                            {isDisabled && (
                                <div
                                    className={`card-button left ${colorMap.true}`} onClick={(e) => {
                                        e.stopPropagation();
                                        onButtonClick();
                                    }}>
                                    <div className="card-button-title">Vertrag hinzufügen</div>
                                    <img src={chevronRightWhite} className="chevron-button" />
                                </div>
                            )}
                        </>
                    )}
                    {tokensCount !== undefined && (
                        <>
                            <div className="tokens-label">{tokensCount} Tokens</div>
                            <div className="tokens-description">
                                Zum <br />
                                Cashback Konto
                            </div>
                        </>
                    )}
                    {description && <div className="description">{description}</div>}
                </div>
            </div>
            {!isDisabled && <img src={chevron} className="chevron" />}
        </div>
    );
};

export default ContractsCard;
