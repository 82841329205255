/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingFinApiError
 * path: /onboarding/vertrag/import-bank-error
 *
 * this is a error page that could be shown after finapi triggers an error
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/app.scss';
import '../../styles/V1/onboarding.scss';

const FinAPIErrorPage = observer(() => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const next = () => {
        navigate(pageUrl.onboardingContractList);
    };

    //main rendering
    return (
        <div className="app onboarding-finapi-error-page">
            <Header expertTitle={trans.t('onboardingFinAPIErrorPage', 'expertTitle')} expertMsg={trans.t('onboardingFinAPIErrorPage', 'expertMsg')} expertClass="expert-info">
                <div className="wrapper-vsmall">
                    <Button role="btnNext" text={trans.t('onboardingFinAPIErrorPage', 'btnTitle')} onClick={next} isRightIcon isWhite isFullWidth className="margin-top-32" />
                </div>
            </Header>
            <BottomLinks isBgBlue />
        </div>
    );
});

export default FinAPIErrorPage;
