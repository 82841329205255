/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingBrokerSuccess
 * path: /onboarding/makler/success
 *
 * this is a success page that will be shown after broker signed successfully
 */

import React from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/theme/Header';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import Alert from '../../components/popups/Alert';
import pageUrl from '../../const/pages';
import trans from '../../trans';
import '../../styles/V1/app.scss';
import '../../styles/V1/onboarding.scss';

const BrokerSuccessPage = observer(({ UserStore }) => {
    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //button handler
    const next = () => {
        navigate(pageUrl.authSignupStepOne);
    };

    //main rendering
    return (
        <div className="app general-message-page">
            <Header
                expertTitle={trans.t('onboardingBrokerSuccessPage', 'expertTitle')}
                expertMsg={trans.t('onboardingBrokerSuccessPage', UserStore.isCompany ? 'expertMsgCompany' : 'expertMsg')}
                expertClass="expert-info"
            >
                <div className="wrapper-vsmall">
                    <Button role="btnNext" text={trans.t('onboardingBrokerSuccessPage', 'btnTitle')} onClick={next} isRightIcon isWhite isFullWidth className="margin-top-32" />
                </div>
            </Header>
            <BottomLinks isBgBlue />
            <Alert message={trans.t('onboardingBrokerSuccessPage', 'successMsg')} />
        </div>
    );
});

export default BrokerSuccessPage;
