import React, { useState } from 'react';
import logo from '../../assets/logo.svg';
// import notifications from '../../assets/header/notifications.svg';
import supportAgent from '../../assets/header/support-agent.svg';
import menu from '../../assets/header/menu.svg';
import SlideMenu from './SlideMenu';
import { useNavigate } from 'react-router-dom';
import chevronRight from '../../assets/chevron-right.svg';

const Header = ({ backButton, title, noMenu }) => {
    const navigate = useNavigate();
    const [isOpenedSlide, setIsOpenedMenu] = useState(false);

    const openSlideMenu = () => {
        setIsOpenedMenu(true);
    };

    return (
        <div className="header-wrapper">
            {backButton ? (
                <img src={chevronRight} className="back-button" onClick={() => navigate(-1)} />
            ) : (
                <a href="/" className="logo">
                    <img src={logo} alt="logo" className="logo" />
                </a>
            )}
            {title && (
                <div className={`title ${!noMenu ? 'with-menu' : ''}`}>{title}</div>
            )}
            {!noMenu && (
                <div className="right-side">
                    {/* <a href="/" className="logo">
                        <img src={notifications} alt="logo" className="logo" />
                    </a> */}
                    <a href="/" className="logo">
                        <img src={supportAgent} alt="logo" className="logo" />
                    </a>
                    <div className="logo" onClick={openSlideMenu}>
                        <img src={menu} alt="logo" className="logo" />
                    </div>
                </div>
            )}
            <SlideMenu isOpened={isOpenedSlide} closeFunc={() => setIsOpenedMenu(false)} />
        </div>
    );
};

export default Header;
