import React from 'react';

import infoIcon from '../../assets/infoIcon.svg';

const CashbackCard = ({ green, title, subtitle, tokens, totalSum, onInfoClick }) => {
    return (
        <div className={`cashback-card ${green ? 'green' : ''}`}>
            <div className="card-header">
                <div>{title}</div>
                {onInfoClick && <img src={infoIcon} onClick={onInfoClick} />}
            </div>
            <div className="subtitle">{subtitle}</div>
            <div className="divider" />
            <div className="values">
                <div className="tokens">{tokens} Token</div>
                <div className="divider" />
                <div className="currency">{totalSum} €</div>
            </div>
        </div>

    );
};

export default CashbackCard;
