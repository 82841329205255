
import React, { useRef } from 'react';
import { observer } from 'mobx-react';

import Header from '../../components/theme/HeaderV2';
import Button from '../../components/dashboard/Button';
import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import pages from '../../const/pages';
import config from '../../config';
import Alert from '../../components/popups/Alert';
import trans from '../../trans';
import '../../styles/V1/app.scss';

const SharingPage = observer(({ UserStore }) => {
    //ref variable for popup
    const alertRef = useRef();
    const { customer } = UserStore;
    const navigate = useNavigate();

    //get referral link
    const refLink = config.webappUrl + '/?referalID=' + customer.phoneNumber;

    const onImageDownload = () => {
        const svg = document.getElementById('QRCode');
        // eslint-disable-next-line
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        // eslint-disable-next-line
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL('image/png');
            const downloadLink = document.createElement('a');
            downloadLink.download = 'QRCode';
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };

    const copyLink = async () => {
        const refLink = config.webappUrl + '/?referalID=' + customer.phoneNumber;
        await navigator.clipboard.writeText(refLink);
        alertRef.current.show(trans.t('referralShareQR', 'alertMessage'));
    };

    return (
        <div className="app secure-dashboard-page">
            <Header backButton title={trans.t('sharingPage', 'headerTitle')} />
            <div className="main-content sharing-page">
                <div className="gray-box">
                    <div className="title">{trans.t('sharingPage', 'shareCommunity')}</div>
                    <div className="description">{trans.t('sharingPage', 'shareEnthusiasm')}</div>
                </div>
                <Button
                    title={trans.t('sharingPage', 'copyLink')}
                    yellow
                    big
                    onClick={copyLink}
                />
                <Button
                    title="QR Code herunterladen"
                    yellow
                    big
                    onClick={onImageDownload}
                />
                <div className="gray-box">
                    <div className="title">{trans.t('sharingPage', 'becomePartner')}</div>
                    <div className="description center">{trans.t('sharingPage', 'getStarted')}</div>
                    <Button
                        title={trans.t('sharingPage', 'businessPartner')}
                        yellow
                        big
                        onClick={() => navigate(pages.businessPartnerWerden)}
                    />
                </div>
                <div style={{ display: 'none' }}>
                    <QRCode
                        id="QRCode" size={146} value={refLink} />
                </div>
            </div>
            <Alert ref={alertRef} />
        </div>
    );
});

export default SharingPage;
