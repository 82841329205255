/**
 * Created by Max Gornostayev on 6/1/22
 *
 * this is a widget for showing a search element with selecting filter and order
 */

import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import trans from '../../trans';

export default function SearchWidget({ onChange, filterList, isShowOrder, defaultText, defaultFilter, defaultOrder }) {
    //state variables
    const [searchText, setSearchText] = useState(defaultText || '');
    const [filterValue, setFilterValue] = useState(defaultFilter || '');
    const [orderValue, setOrderValue] = useState(defaultOrder || 'asc');

    //default values
    filterList = filterList || [];
    isShowOrder = isShowOrder !== false;

    //did mount variable
    const didMount = React.useRef(false);

    //handler for changing value
    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        if (onChange) {
            onChange(searchText, filterValue, orderValue);
        }
    }, [searchText, filterValue, orderValue]);

    //handler for changing search
    const onChangeSearch = (event) => {
        const { value } = event.target;
        setSearchText(value);
    };

    //handler for changing filter
    const onChangeFilter = (obj) => {
        setFilterValue(obj.value);
    };

    //handler for changing order
    const onChangeOrder = (obj) => {
        setOrderValue(obj.value);
    };

    //select components
    const DropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
            <div className="btn-drop" />
        </components.DropdownIndicator>
    );
    const Placeholder = () => null;
    const IndicatorSeparator = () => null;
    const ValueContainer = () => null;

    //order list values
    const orderList = [
        { value: 'asc', label: trans.t('elements', 'asc') },
        { value: 'desc', label: trans.t('elements', 'desc') },
    ];

    return (
        <div className="search-widget-new">
            <div className="inner">
                <div className="search">
                    <input placeholder={trans.t('elements', 'searchPlaceholder')} value={searchText} onChange={onChangeSearch} />
                </div>
                {isShowOrder && (
                    <Select
                        components={{ ValueContainer, Placeholder, IndicatorSeparator, DropdownIndicator }}
                        classNamePrefix="order"
                        options={orderList}
                        isSearchable={false}
                        isClearable={false}
                        defaultValue={defaultOrder ? filterList.filter(({ value }) => value === defaultOrder) : ''}
                        onChange={onChangeOrder}
                    />
                )}
                <Select
                    components={{ ValueContainer, Placeholder, IndicatorSeparator, DropdownIndicator }}
                    classNamePrefix="filter"
                    options={filterList}
                    isSearchable={false}
                    isClearable={false}
                    defaultValue={defaultFilter ? filterList.filter(({ value }) => value === defaultFilter) : ''}
                    onChange={onChangeFilter}
                />
            </div>
        </div>
    );
}
