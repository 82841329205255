import React from 'react';

import chevronRightWhite from '../../assets/V2/chevronRightWhite.svg';

const Button = ({ onClick, title, green, yellow, brown, className, withoutArrow }) => {
    const colorMap = {
        [green]: 'green',
        [yellow]: 'yellow',
        [brown]: 'brown',
        [!green && !yellow && !brown]: 'blue',
    };

    return (
        <div
            className={`card-button ${colorMap.true} ${className}`} onClick={(e) => {
                e.stopPropagation();
                onClick?.();
            }}>
            <div className="card-button-title">{title}</div>
            {!withoutArrow && <img src={chevronRightWhite} className="chevron-button" />}
        </div>
    );
};

export default Button;
