/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows a input text
 */

import React from 'react';
import PropTypes from 'prop-types';

class InputText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.defaultValue,
            validateMsg: '',
            isValid: true,
        };
        this.refInput = React.createRef();
    }

    //on change handler
    onChange = (event) => {
        const { value } = event.target;

        const { isValid, validateMsg } = this.validate(value);

        this.setState({ value, isValid, validateMsg });

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    //do validation
    validate = (checkValue) => {
        const value = checkValue || this.state.value;

        let isValid = true;
        let validateMsg = '';
        if (this.props.validateFunc) {
            const validObj = this.props.validateFunc(value);
            if (!validObj.validated) {
                isValid = false;
                validateMsg = validObj.msg;
            }
        }

        return { isValid, validateMsg };
    };

    //set value
    setValue = (value) => {
        this.setState({ value });
    };

    //set validation msg
    setValidation = (isValid, validateMsg) => {
        this.setState({ isValid, validateMsg });
    };

    //onclick function
    onClick = () => {
        if (!this.props.readOnly) this.refInput.current.focus();
    };

    render() {
        let className = 'input-field';
        if (this.props.className) {
            className += ' ' + this.props.className;
        }

        const isNotValid = !!this.props.validateFunc && !this.state.isValid;

        let titleClass = 'title';
        if (isNotValid) {
            titleClass += ' red';
        }

        return (
            <div className={className} onClick={this.onClick}>
                <div className="inner-field">
                    {!!this.props.title && <div className={titleClass}>{this.props.title}</div>}
                    {!this.props.readOnly ? (
                        <input
                            ref={this.refInput}
                            role={this.props.role}
                            type={this.props.type}
                            placeholder={this.props.placeholder || ''}
                            value={this.state.value}
                            onChange={this.onChange}
                        />
                    ) : (
                        <div className="text">{this.state.value}</div>
                    )}
                </div>
                {!!this.props.desc && <div className="desc">{this.props.desc}</div>}
                {isNotValid && <div className="error">{this.state.validateMsg}</div>}
            </div>
        );
    }
}

InputText.defaultProps = {
    title: '',
    type: 'text',
    desc: '',
    readOnly: false,
    defaultValue: '',
    placeholder: '',
    className: null,
    role: 'textInputTestId',
    validateFunc: null,
    onChange: () => {},
};
InputText.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    desc: PropTypes.string,
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    role: PropTypes.string,
    validateFunc: PropTypes.func,
    onChange: PropTypes.func,
};

export default InputText;
