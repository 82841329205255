/**
 * Created by Max Gornostayev on 02/15/22
 *
 * pageId: onboardingSignBroker
 * path: /onboarding/makler/sign-maklerauftrag
 *
 * this is a page that shows signing for broker agreement
 */

import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { isMobile } from 'react-device-detect';
import Header from '../../components/theme/Header';
import Bottom from '../../components/theme/Bottom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import YoutubeVideo from '../../components/elements/YoutubeVideo';
import PopupTerms from '../../components/popups/PopupTerms';
import Loading from '../../components/elements/Loading';
import Alert from '../../components/popups/Alert';
import Utils from '../../lib/Utils';
import pageUrl from '../../const/pages';
import config from '../../config';
import trans from '../../trans';
import '../../styles/V1/onboarding.scss';
import '../../styles/V1/app.scss';

const SignBrokerPage = observer(({ UserStore }) => {
    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [isSigned, setIsSign] = useState(false);

    //ref variables
    const refSign = useRef();
    const refAlert = useRef();
    const popupTermsRef = useRef();

    //get customer
    const { customer, isCompany } = UserStore;

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    //component did mount handler
    useEffect(() => {
        const onWindowResize = () => {
            const canvas = refSign.current.getCanvas();
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
        };
        onWindowResize();
        // window.addEventListener('resize', onWindowResize);
        // return () => window.removeEventListener('resize', onWindowResize);
    }, []);

    //remove sign
    const removeSign = () => {
        refSign.current.clear();
        setIsSign(false);
    };

    //starting signing
    const onStartSign = () => {
        setIsSign(true);
    };

    //handling next button click
    const next = async () => {
        let signBase64 = refSign.current.getTrimmedCanvas().toDataURL('image/png');
        setIsLoading(true);
        signBase64 = await Utils.resizeBase64Image(signBase64);
        if (!signBase64) {
            refAlert.current.error(trans.t('errors', 'firstSignIsEmpty'));
            return false;
        }

        setIsLoading(false);
        navigate(pageUrl.onboardingSignPrivacy, { state: { signBase64 } });
    };

    const canvasProps = { height: 260, className: 'signCanvas' };
    if (isMobile) {
        canvasProps.width = window.innerWidth - 40;
    }

    let signNameText = trans.t('onboardingSignBrokerPage', 'preNameText');
    if (isCompany) {
        signNameText = customer.companyNameValue + trans.t('onboardingSignBrokerPage', 'preNameCompanyText');
    }

    return (
        <div className="app onboarding-sign-broker-page">
            <Header expertMsg={trans.t('onboardingSignBrokerPage', 'expertMsg')} isBack />
            <div className="content">
                <div className="wrapper">
                    <h1>{trans.t('onboardingSignBrokerPage', 'h1')}</h1>
                    <div className="link-text">
                        {trans.t('onboardingSignBrokerPage', 'textInfo1')}
                        <span className="underline" onClick={() => popupTermsRef.current.show('info')}>
                            {trans.t('onboardingSignBrokerPage', 'linkInfo1')}
                        </span>
                        {trans.t('onboardingSignBrokerPage', 'textInfo2')}
                        <a className="underline" href={config.urls.brokerTemplate} target="_blank" rel="noreferrer">
                            {trans.t('onboardingSignBrokerPage', 'linkInfo2')}
                        </a>
                        {trans.t('onboardingSignBrokerPage', 'textInfo3')}
                    </div>
                    <div className="container-sign">
                        <div className="inner">
                            <SignatureCanvas ref={refSign} canvasProps={canvasProps} onBegin={onStartSign} />
                        </div>
                        <div className="container-sign-bottom">
                            <div className="name">
                                {signNameText} {customer.fullName}
                            </div>
                            <div className="remove-sign" onClick={removeSign} />
                        </div>
                    </div>
                    <div className="bottom-text">{trans.tHTML('onboardingSignBrokerPage', 'text1')}</div>
                    <div className="bottom-text">{trans.tHTML('onboardingSignBrokerPage', 'text2')}</div>
                    <div className="bottom-text">{trans.tHTML('onboardingSignBrokerPage', 'text3')}</div>
                    <h2>{trans.t('onboardingSignPrivacyPage', 'youtubeTitle')}</h2>
                    <YoutubeVideo videoId={config.youtube.onboardingSign} width={733} height={416} autoplay={0} />
                </div>
            </div>
            {isSigned && (
                <Bottom>
                    <Button role="btnNext" text={trans.t('onboardingSignBrokerPage', 'btnNext')} onClick={next} isRightIcon />
                </Bottom>
            )}
            <BottomLinks />
            <PopupTerms ref={popupTermsRef} />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SignBrokerPage;
