import React from 'react';

import cancelFilled from '../../assets/cancelFilled.svg';

const PopupModal = ({ open, closeModal, title, text }) => {
    return (
        <div className={`popup-container ${open ? 'opened' : ''}`}>
            <div className="close-button" onClick={closeModal}>
                <img src={cancelFilled} className="close-icon" />
            </div>
            <div className="popup-title">{title}</div>
            <div className="popup-text">{text}</div>
            <div className="popup-button" onClick={closeModal}>
                <div className="popup-button-title">Schließen</div>
            </div>
        </div>
    );
};

export default PopupModal;
