/**
 * Created by Max Gornostayev on 06/25/22
 *
 * This is class that provides an ability to send requests to the server with JWT and without authorization.
 * So endpoints like:
 * - /api/private/v1/token/refresh
 * - /api/private/v1/register/email/send
 * - /api/private/v1/register/email/confirmation
 * - /api/private/v1/register/password
 * - /api/private/v1/login/pass
 * - /api/private/v1/login/biometric
 * - all finapi requests to the our BrokerCRM server
 * - /api/private/v1/list-element-full
 */

import jwt from 'jwt-encode';
import API from './API';
import codes from '../const/codes';
import DateUtil from '../lib/DateUtil';
import config from '../config';
import trans from '../trans';

class JwtAPI extends API {
    static instance;
    token = '';
    finAPIToken = '';

    static getInstance() {
        if (!JwtAPI.instance) {
            JwtAPI.instance = new JwtAPI();
        }
        return JwtAPI.instance;
    }

    constructor() {
        super({ baseURL: process.env.REACT_APP_API_URL, contentType: 'application/json' });
    }

    /*
     * This is a function that sends a request to the BrokerCRM server
     *
     * @param method - string - method of the request like POST, GET, etc
     * @param version - string - version of the endpoint
     * @param url - string - the path of endpoint
     * @param data - object - params of the body {'name': value, ...}
     * @param params - object - params of the body {'name': value, ...}
     * @param skipAuthorization - bool - should we skip step for authorization or no
     * @param timeout - number - timeout of the request
     * @return object of the response, check super.getResponseObj
     */
    async request(method, version, url, data, params, skipAuthorization, timeout) {
        const startTimestamp = DateUtil.timestamp();

        url = '/api/private/v' + version + url;

        timeout = timeout || config.api.timeout;

        //forimg params for request
        const conf = {
            method,
            url,
            timeout,
        };
        if (data) conf.data = data;
        if (params) conf.params = params;
        //check if token is exissted, if not do authorization
        if (!this.getToken()) {
            const isAuthorized = await this.authorize();
            if (!isAuthorized) {
                return this.getNotAuthorizeReponse();
            }
        }

        //try to get first response
        let response = await super.sendRequest(conf);

        // if bearer token is expired, try to get new token and send request again
        if (response.code === codes.statusNotAuthorize && !skipAuthorization) {
            const isAuth = await this.authorize();
            if (!isAuth) {
                this.removeToken();
                return this.getNotAuthorizeReponse();
            }
            response = await super.sendRequest(conf);
        }

        //show log into console for debug mode
        if (super.isDebug) {
            const executedTime = DateUtil.timestamp() - startTimestamp;
            // eslint-disable-next-line
            console.log('Endpoint - ' + url + ' ::: Executed time - ' + executedTime + ' seconds');
        }

        // process a response and return a data if it's possible
        return response;
    }

    /*
     * get token from crm broker server
     */
    async authorize() {
        const { t, token } = this.getJWTToken();

        const conf = {
            method: 'GET',
            url: config.api.getTokenPath,
            timeout: config.api.timeout,
            params: { t, token },
        };
        const res = await super.sendRequest(conf);
        if (res.status) {
            this.updateToken(res.data.token);
        }
        return res.status;
    }

    /*
     * get status of not authorize response, when you have mistake during getting bearer token from server
     */
    getNotAuthorizeReponse() {
        return super.getResponseObj(false, codes.statusNotAuthorize, {}, trans.t('codeResponsesJwtAPI', 'statusNotAuthorize'), 'noAuth');
    }

    /*
     * forming JWT token
     */
    getJWTToken() {
        const t = DateUtil.timestamp(true);
        const payload = {
            tmts: t,
            slt: process.env.REACT_APP_API_AUTH_SALT,
        };
        const token = jwt(payload, process.env.REACT_APP_API_AUTH_SECRET);
        return { t, token };
    }

    /*
     * set finAPI token
     */
    setFinAPIToken(val) {
        this.finAPIToken = val;
    }

    /*
     * get finAPI token
     */
    getFinAPIToken(val) {
        return this.finAPIToken;
    }

    /*
     * update token in axios instance
     */
    updateToken(token) {
        this.setHeaderCommon('Authorization', `Bearer ${token}`);
        this.token = token;
    }

    /*
     * remove token in axios instance
     */
    removeToken() {
        this.setHeaderCommon('Authorization', '');
        this.token = '';
    }

    /*
     * get token
     */
    getToken() {
        return this.token;
    }
}

export default JwtAPI.getInstance();
