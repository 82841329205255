const objModel = {
    contract: {
        contractId: null,
        customerId: null,
        contractTempID: null,
        provider: '',
        productType: '',
        customProvider: '',
        status: 'active',
        number: '',
        paymentMethod: '',
        price: 0,
        currency: 'EURO',
        dateStart: null,
        dateEnd: null,
        originalStartDate: null,
        isYearlyExtension: true,
        notes: '',
        numberDocuments: 0,
        tariffs: [],
        forecastTokenValues: 0,
    },
    finAPI: {
        accountOwner: '',
        bankName: '',
        accountIban: '',
    },
    brokerTransmission: {
        brokerStatus: '',
        brokerServiceStartedAt: null,
        brokerServiceCanceledAt: null,
    },
};

export default objModel;
