import React from 'react';
import BottomBar from './BottomBar';

export default function PageWrapper({ header, children, className, contentClassName, modals }) {
    return (
        <div className={`main-wrapper ${className || ''}`}>
            {header}
            <div className={`main-content-new ${contentClassName}`}>{children}</div>
            <BottomBar />
            {modals}
        </div>
    );
}
