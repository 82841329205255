/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is configuration file with public information
 */

import countries from '../const/countries';

const config = {
    env: 'owl-beta',
    webappUrl: 'https://beta-webapp.go-digital-software.de',
    isDebug: true,

    api: {
        timeout: 45000,
        getTokenPath: '/api/private/v1/token/jwt',
    },

    storesExpiretion: {
        customer: 4320000, //12 hours
        contracts: 360000, //1 hour
        data: 4320000, //12 hours
    },

    urls: {
        brokerTemplate: 'https://support.owl-direkt.de/~9ahPt',
        privacyTemplate: 'https://support.owl-direkt.de/~9ahPt',
        impressumLink: 'https://www.owl-versicherungskontor.de/service/service/impressum.html',
        newTicket: 'https://owl-direkt.de/vergleichsrechner/',
    },

    bitrix24Urls: {
        supportUrl: 'http://',
        contractPopupUrl: 'http://',
        cancelBroker: 'https://',
    },

    finAPI: {
        env: 'live',
        timeout: 60000,
        profileId: 'f373b840-6c14-4121-9ba9-c6a1f4a02adf',
        urls: {
            access: 'https://live.finapi.io/api/v1',
            webform: 'https://webform-live.finapi.io/api',
            data: 'https://di-live.finapi.io/api',
        },
    },

    youtube: {
        onboardingFinapi: 'LpDfl6CdpaE',
        onboardingInfo: '0ctmX_7mGqk',
        onboardingSign: '0ctmX_7mGqk',
    },

    dateFormat: {
        week: 'MM/DD',
        serverDate: 'YYYY-MM-DD',
        serverDateWithTime: 'YYYY-MM-DD HH:mm:ss',
        date: 'DD.MM.YYYY',
        logDate: 'YYYYMMDDHms',
    },

    digit: {
        decimalSeparator: ',',
        separator1000: '.',
    },

    countries: ['deutschland', 'austrian'],

    currency: {
        title: 'EUR',
        sign: '€',
    },

    validationCountryCode: countries.deutschland.code,
};
export default config;
