/**
 * Created by Ilya Savinov 29/01/24
 *
 * this is root store
 */
import DataStore from './DataStore';
import UserStore from './UserStore';
import ContractsStore from './ContractsStore';

class RootStore {
  UserStore;

  DataStore;

  ContractsStore;

  constructor() {
      this.UserStore = new UserStore(this);
      this.DataStore = new DataStore(this);
      this.ContractsStore = new ContractsStore(this);
  }
}

export default RootStore;
