/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is user store to save all customer data
 */

import { makeAutoObservable, action, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import Customer from '../models/entity/Customer';
import DateUtil from '../lib/DateUtil';
import codes from '../const/codes';
import config from '../config';

class UserStore {
    /*
     * this is contract to add in onboarding
     */
    customer = new Customer();

    /*
     * query params
     */
    urlParams = new Map();

    /*
     * customer id
     */
    id = null;

    /*
     * auth token
     */
    authToken = null;

    /*
     * refresh token
     */
    refreshToken = null;

    /*
     * agree date when customer click agree in terms page
     */
    agreeDate = null;

    /*
     * is company or private
     */
    isCompany = false;

    /*
     * is persist store loaded
     */
    isLoaded = false;

    /*
     * This is constructor
     */
    constructor() {
        makeAutoObservable(this);

        makePersistable(this, {
            name: 'BancassuranceUserStore',
            properties: ['id', 'agreeDate', 'authToken', 'refreshToken', 'isCompany'],
            storage: window.localStorage,
            removeOnExpiration: true,
            expireIn: config.storesExpiretion.customer,
            debugMode: false,
        }).then(
            action(async (persistStore) => {
                if (persistStore.isHydrated && (this.authToken || this.refreshToken)) {
                    this.updateAuthTokens();
                    await this.loadStore();
                }
                this.setIsLoaded(true);
            })
        );
    }

    /*
     * get boolean value is terms accepted by user
     */
    get isNotTermsAccepted() {
        return !this.agreeDate;
    }

    /*
     * get boolean value is user authorized and had a authToken and refreshToken
     */
    get isAuthorized() {
        return !!this.authToken && !!this.refreshToken;
    }

    /*
     * get url params object
     */
    get urlParamsObj() {
        return Object.fromEntries(this.urlParams);
    }

    /*
     * clear store user store
     */
    clearStore(agreeDate) {
        this.customer = new Customer();
        runInAction(() => {
            this.id = null;
            this.agreeDate = agreeDate || null;
            this.isCompany = false;
        });

        this.setAuthTokens('', '');
    }

    /*
     * set loaded store or no
     */
    setIsLoaded(value) {
        runInAction(() => {
            this.isLoaded = value;
        });
    }

    /*
     * set loaded store or no
     */
    setIsCompany(value) {
        runInAction(() => {
            this.isCompany = value;
        });
    }

    /*
     * set loaded store or no
     */
    setAgreeDate(value) {
        this.agreeDate = value;
    }

    /*
     * set agree date
     */
    setTermsAgreeDate() {
        if (!this.agreeDate) {
            const d = DateUtil.getFormatServerDate();
            this.agreeDate = d;
            const { customer } = this;
            customer.setValue('acceptTermsOfUseAt', d);
            this.update({ customer });
        }
    }

    /*
     * update store objects
     */
    update({ customer }) {
        if (customer) {
            this.customer = customer;
        }
    }

    /*
     * set affiliate ids to store to save it next when create a customer
     */
    setAffiliateIds({ affiliateID, referalID }) {
        const { customer } = this;
        customer.setAffiliateIds({ affiliateID, referalID });
        this.update({ customer });
    }

    /*
     * set tokens to store
     */
    setAuthTokens(authToken, refreshToken) {
        if (authToken || authToken === '') {
            runInAction(() => {
                this.authToken = authToken;
            });
        }
        if (refreshToken || refreshToken === '') {
            runInAction(() => {
                this.refreshToken = refreshToken;
            });
        }
        this.updateAuthTokens();
    }

    /*
     * set url params
     */
    setUrlParams(params) {
        params = typeof params === 'object' ? params : {};
        this.urlParams = new Map(Object.entries(params));
    }

    /*
     * update tokens in API object from Persist store
     */
    updateAuthTokens() {
        Customer.setAuthTokens(this.authToken, this.refreshToken);
    }

    /*
     * load store from server
     */
    async loadStore() {
        const res = await this.loadCustomer();

        if (!res.status && res.code === codes.statusNotAuthorize) {
            this.setAuthTokens('', '');
        }
        this.setAgreeDate(this.customer.acceptTermsOfUseAt);
        this.setIsCompany(this.customer.isCompany);

        return res;
    }

    /*
     * load customer from server
     */
    async loadCustomer() {
        const { customer } = this;

        const res = await customer.load();
        this.update({ customer });

        return res;
    }

    /*
     * save customer
     * @fields - object (fieldsName: fieldValue, ...}
     */
    async upsertCustomer(fieldsObj) {
        const { customer } = this;
        const res = customer.isTemp ? await customer.create({ ...fieldsObj }, this.isCompany) : await customer.update({ ...fieldsObj });
        this.update({ customer });
        if (res.status && res.data.auth) {
            this.setAuthTokens(res.data.auth.authToken);
            this.setUrlParams();
        }

        return res;
    }

    /*
     * update phone and email, it's using in first step registration proccess
     */
    async updateEmailPhone({ email, phoneCode, phoneNumber }) {
        const { customer } = this;
        customer.setObject({ email, phoneCode, phoneNumber });
        const res = await customer.sendOTP();
        this.update({ customer });

        return res;
    }

    /*
     * sign Broker
     * @fields - object {signBrokerBase64, signPrivacyBase64, isContactingAccepted, isNewServiceAccepted, isMarketingAccepted, contractIds}
     */
    async signBroker(fieldsObj) {
        const { customer } = this;
        const res = await customer.signBroker(fieldsObj);
        if (res.status) {
            this.update({ customer });
        }

        return res;
    }

    /*
     * update password
     * @password - string
     * @token - string
     */
    async updatePassword(password, token) {
        const res = await Customer.updatePassword(password, token);
        return res;
    }

    /*
     * save query params from url to user fields: ID, First Name, Last Name, Street, City, PostalCode, Birthdate, Email, Mobil Number
     * url params: customerId=123&firstName=Max&lastName=Mustemann&city=Berlin&street=main 12&postalCode=12345&birthdate=DD.MM.YYYY&email=max@mustermann.com&phoneNumber=123456789
     * @param searchParams - object
     */
    setUserDataFromQuery(searchParams) {
        const customerId = searchParams.get('customerId') || '';
        const firstName = searchParams.get('firstName') || '';
        const lastName = searchParams.get('lastName') || '';
        const city = searchParams.get('city') || '';
        const street = searchParams.get('street') || '';
        const postalCode = searchParams.get('postalCode') || '';
        const birthdate = searchParams.get('birthdate') || '';
        const email = searchParams.get('email') || '';
        const phoneNumber = searchParams.get('phoneNumber') || '';
        const affiliateID = searchParams.get('affiliateID') || '';
        const referalID = searchParams.get('referalID') || '';
        const externalId = searchParams.get('externalID') || '';
        if (customerId || firstName || lastName || street || birthdate || email || phoneNumber || affiliateID || referalID || externalId) {
            this.setUrlParams({
                customerId,
                firstName,
                lastName,
                city,
                street,
                postalCode,
                birthdate,
                email,
                phoneNumber,
                affiliateID,
                referalID,
                externalId,
            });
        }
    }

    /*
     * set user data from query params that is stored in the used object
     */
    setUserDataFromStore() {
        const { customer } = this;
        customer.setObject(this.urlParamsObj);
        this.update({ customer });
    }

    /*
     * confirm email with token
     * @param emailToken - string
     */
    async emailConfirm(emailToken) {
        const { customer } = this;
        const res = await customer.emailConfirm(emailToken);
        if (res.status) {
            this.update({ customer });
        }
        return res;
    }

    /*
     * do login and load the customer
     * @param phoneCode - string
     * @param phoneNumber - string
     * @param password - string
     */
    async doLoginWithPhone({ phoneCode, phoneNumber, password }) {
        let res = { status: false, msg: 'No params' };

        if (phoneCode && phoneNumber && password) {
            res = await Customer.loginWithPhoneAndPass(phoneCode, phoneNumber, password);
        }
        if (res && res.status && res.data.auth) {
            this.setAuthTokens(res.data.auth.authToken, res.data.auth.refreshToken);
            res = await this.loadStore();
        }
        return res;
    }

    /*
     * do login and load the customer
     * @param email - string
     * @param password - string
     */
    async doLoginWithEmail({ email, password }) {
        let res = { status: false, msg: 'No params' };

        if (email && password) {
            res = await Customer.loginWithEmailAndPass(email, password);
        }
        if (res && res.status && res.data.auth) {
            this.setAuthTokens(res.data.auth.authToken, res.data.auth.refreshToken);
            res = await this.loadStore();
        }
        return res;
    }
}

export default UserStore;
