/**
 * Created by Max Gornostayev on 02/15/22
 *
 * this is a component that shows a checkbox list
 */

import React from 'react';
import PropTypes from 'prop-types';

class CheckboxList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { selectedList: !this.props.defaultValue ? [] : [this.props.defaultValue] };
    }

    setSelectedList = (selectedList) => this.setState({ selectedList });

    onSelect = (val) => {
        let newList = [];
        if (this.props.isMultiselect) {
            newList = this.state.selectedList;
        }
        newList.push(val);

        this.setSelectedList(newList);

        if (this.props.onClick) {
            const valToPass = this.props.isMultiselect ? newList : val;
            this.props.onClick(valToPass);
        }
    };

    unselectAll = () => {
        if (this.state.selectedList.length) {
            this.setSelectedList([]);
        }
    };

    render() {
        return (
            <div className="checkboxlist" role={this.props.role}>
                {this.props.list.map((obj, i) => (
                    <div key={i} className="checkbox" onClick={() => this.onSelect(obj.id)}>
                        <div className="checkbox-inner">
                            <div className={this.state.selectedList.indexOf(obj.id) !== -1 ? 'element checked' : 'element'} />
                        </div>
                        <div className="checkbox-title">{obj.title}</div>
                    </div>
                ))}
            </div>
        );
    }
}

CheckboxList.defaultProps = {
    list: [],
    isMultiselect: false,
    defaultValue: null,
    role: 'textCheckboxListTestId',
    onClick: null,
};
CheckboxList.propTypes = {
    list: PropTypes.any,
    isMultiselect: PropTypes.bool,
    defaultValue: PropTypes.any,
    role: PropTypes.string,
    onClick: PropTypes.func,
};

export default CheckboxList;
